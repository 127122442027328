/* width */
.scrollbar-container::-webkit-scrollbar {
  display: none;
}
#wrapper::-webkit-scrollbar {
  display: none;
}
/* Handle */
.scrollbar-container::-webkit-scrollbar-thumb {
  background: #eee;
}
/* Handle on hover */
.scrollbar-container::-webkit-scrollbar-thumb:hover {
  background: #eee;
}

.scrollbar-container::-webkit-scrollbar {
  width: 5px;
}

.scrollbar-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.3);
  background: transparent;
  // border: 1px solid #aaa;
}

.scrollbar-container::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.5);
  background: #aaa;
  background-clip: content-box;
  border: 1px solid #aaa;
  border-radius: 100px;
}

.scrollbar-container::-moz-any(#content, #appcontent) browser {
  margin-right: -14px !important;
  overflow-y: scroll;
  margin-bottom: -14px !important;
  overflow-x: scroll;
  scrollbar-color: rebeccapurple green;
  scrollbar-width: thin;
}
