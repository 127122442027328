.btn {
  font-size: 0.875rem;
  margin-left: 4px;
  margin-right: 4px;
}
.btn-row-sm {
  padding: 0.15em 0.4em;
  font-size: 0.76563rem;
  border-radius: 0.2rem;
  margin-left: 2px;
  margin-right: 2px;
}
.card {
  margin-bottom: 15px;
}
.card-header {
  background-color: #2a526dcc !important;
  color: #fff !important;
}
.card-footer {
  text-align: right;
}
.form-control {
  font-size: 0.875rem;
}
.nav-link:hover {
  cursor: pointer;
}
.swal2-title {
  font-size: 1.25em !important;
}
.text-center {
  text-align: center;
}
.table td,
.table th {
  padding: 8px;
}
.w-90 {
  width: 90vw !important;
  max-width: 90vw !important;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
