body {
  font-family: "Kanit", sans-serif !important;
  color: #000;
}
#sidebar-wrapper {
  z-index: 100;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
  margin-left: 0;
  position: fixed;
}
#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 0.9rem;
  font-weight: 700;
  text-align: left;
  background-color: #0e1627;
  color: #fff;
}
#sidebar-wrapper .list-group {
  width: 15rem;
}
#page-content-wrapper {
  min-width: 100vw;
  padding-top: 20px;
}
#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}
#wrapper {
  margin-top: 65px !important;
  flex-direction: row;
  flex-grow: 1;
  overflow-x: visible;
  min-height: calc(100vh - 65px);
  background-color: #eee;
}
#page-content-wrapper {
  margin-left: 250px;
}
#wrapper.toggled #sidebar-wrapper {
  margin-left: -250px;
}
.app {
  display: flex;
  flex-direction: column;
}
.app-header {
  z-index: 101;
  height: 65px;
  position: fixed;
  flex-direction: row;
  padding: 0;
  margin: 0;
  background-color: #fff;
  border-bottom: 1px solid #c8ced3;
}
.app-header-sub {
  height: 55px;
  width: 100vw;
  display: inline-flex;
}
.img-logo {
  height: 45px;
  width: auto;
  justify-content: center;
}
.sidebar .nav-link .badge {
  position: absolute;
  right: 0;
  margin: auto 8px auto auto;
  width: 20px;
}
.sidebar-menu {
  background-color: #0e1627 !important;
  color: #4d8af0 !important;
}
.sidebar-menu-group {
  margin-left: 0px;
}
.sidebar-title-list {
  background-color: #ed1b24;
  color: #fff;
  padding: 0.75rem 1.25rem;
}
.sidebar-list {
  background-color: #fff;
  color: #5b5b5b;
  font-size: 0.8rem;
  display: block;
  padding: 0.75rem 1.25rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.sidebar-list .fa {
  margin-right: 16px;
}
.sidebar-list.active {
  background-color: #f36523;
  border-radius: 3px !important;
  color: #fff;
}
.sidebar-list .badge {
  font-size: 100%;
  position: absolute;
  right: 8px;
}
.d-flex .toggled .sidebar-menu-group {
  margin-left: 0px;
}
.d-flex .toggled .scrollbar-container {
  width: 250px !important;
}
.card-footer {
  text-align: right;
}
.scrollbar-container {
  overflow-anchor: none;
  touch-action: pan-up;
  position: absolute;
  overflow-y: scroll;
  height: calc(100vh - 65px);
  width: 250px !important;
  padding-bottom: 180px;
}
nav {
  border-bottom: 1px solid grey;
}

nav > ul {
  padding-left: 0;
}

nav > ul > li {
  display: inline-block;
  margin-right: 10px;
}

nav > ul > li > a.active {
  color: red;
}

footer {
  border-top: 1px solid grey;
  text-align: center;
}
.img-upload {
  max-width: 100%;
  max-height: 80%;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  box-shadow: 5px 5px 15px 5;
}
.img-upload-box {
  max-width: 100%;
  max-height: 400px;
  box-shadow: 5px 5px 15px 5;
}
.label-upload {
  width: 100%;
}
.scrollbar-container {
  background-color: #fff !important;
}
.first-view {
  display: flex;
  flex-direction: column;
}
.div-topic {
  flex-grow: 1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.card-topic {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  padding: 1rem;
  margin-top: 8px;
  margin-bottom: 8px;
}
.card-detail {
  margin-top: 16px;
}
.row-contenct {
  padding: 8px;
}
.center {
  align-items: center;
  justify-content: center;
  text-align: center;
}
.right {
  align-items: flex-end;
  justify-content: right;
  text-align: right;
}
.left {
  align-items: flex-start;
  justify-content: left;
  text-align: left;
}
@media (min-width: 768px) {
  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }
  #wrapper.toggled #sidebar-wrapper {
    margin-left: -250px;
  }
  #wrapper.toggled #page-content-wrapper {
    margin-left: 5px;
  }
  #wrapper.toggled .img-logo {
    height: 15px !important;
  }
  .navbar-nav .dropdown-menu {
    position: absolute;
    will-change: transform;
    top: 0px;
    left: 0px;
    transform: translate3d(-10px, 40px, 0px);
  }
}
@media (min-width: 992px) {
  .sidebar-fixed .app-header + .app-body .sidebar {
    height: calc(100vh - 55px);
  }
}
